import * as _ from 'lodash';
import * as Api from '../api/api';
import * as React from 'react';
import * as Colors from '../styles/colors';
import * as FormField from './FormField';
import * as ReduxForm from 'redux-form';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { getText } from '../utils/langTexts';
import { getSubscription, getFirmName } from '../utils/utils';
import PasswordToggle from './PasswordToggle';


type CarrierRatesSettingsFormProps = ReduxForm.InjectedFormProps<Api.CarrierRatesSettingsModel, {}, string>
    & CarrierRatesSettingsFormOwnProps
    & CarrierRatesSettingsFormExternalProps;

interface CarrierRatesSettingsFormOwnProps {
    initialValues: Api.CarrierRatesSettingsModel;
    carriers: { [id: number]: Api.CarrierModel };
    carrierRatesPlatforms: { [id: number]: Api.CarrierRatesPlatformModel };
    subscriptions: Array<Api.SubscriptionModel>;
    carrierId: number;
    subscription: Api.SubscriptionModel;
    agencies: Array<Api.FirmModel>;
    carrierRatesSettings: Array<Api.CarrierRatesSettingsModel>;
    subOkargoId: number;
    firmId: number;
}

interface CarrierRatesSettingsFormExternalProps {
    onSubmit: (values: Api.CarrierRatesSettingsModel) => Promise<any>;
    mode: "Create" | "Update";
}

type CarrierRatesSettingsType = "Client" | "Company";
interface CarrierRatesSettingsFormState {
    type: CarrierRatesSettingsType;
    isPasswordVisible: boolean;
}

const fieldStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12
}

const labelStyle: React.CSSProperties = {
    flex: "0 0 auto",
    minWidth: 200,
    textAlign: "right",
    marginRight: 6
}

const uniqLogin = (value: string, allValues: Api.CarrierRatesSettingsModel, props: CarrierRatesSettingsFormProps) => {
    if (props.carrierRatesSettings
        && value
        && props.carrierRatesSettings
            .some(x => x.carrierRatesPlatformId === allValues.carrierRatesPlatformId
                && x.login === value
                && x.carrierRatesSettingsId !== allValues.carrierRatesSettingsId)) {
        return "This login already exist for this carrier";
    }
    return undefined;
};

const platformBlacklist = ["MSK1", "EMC", "CMA2", "EMC2","CMA4"];
class CarrierRatesSettingsForm extends React.Component<CarrierRatesSettingsFormProps, CarrierRatesSettingsFormState> {

    constructor(props: CarrierRatesSettingsFormProps) {
        super(props);
        this.state = {
            type: "Client",
            isPasswordVisible: false,
        };
    }
    get authenticationType(): Api.CarrierRatesPlatformModelAuthenticationTypeEnum {
        return "Credentials";
    }

    componentWillReceiveProps(nextProps: CarrierRatesSettingsFormProps) {
        if (nextProps.subOkargoId) {
            this.setState({ type: "Client" });
        } else if (nextProps.firmId) {
            this.setState({ type: "Company" });
        }
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props} autoComplete="off" className="form-horizontal container-fluid">
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                {this.props.subscription.allowCarrierRatesManagement
                    && <div style={{ ...fieldStyle }}>
                        <div style={{ ...labelStyle }}></div>
                        <div style={{ flex: 1 }}>
                            <RadioGroup row aria-label="Type" name="Type" value={this.state.type} onChange={(e, value) => this.setState({ type: value as any })}>
                                <FormControlLabel
                                    value="Client"
                                    control={<Radio color="primary" />}
                                    label="Per user"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    value="Company"
                                    control={<Radio color="primary" />}
                                    label="Per company/agency"
                                    labelPlacement="start"
                                />
                            </RadioGroup>
                        </div>
                    </div>}
                {this.state.type === "Company"
                    && <div style={{ ...fieldStyle }}>
                        <label style={{ ...labelStyle }}>Company/agency: </label>
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="firmId"
                                style={{ minWidth: 200 }}
                                validate={[FormField.required]}
                                component={FormField.getSelectField(_.sortBy(this.props.agencies.filter(x=>x.enabled)
                                    .map(x => ({
                                        label: getFirmName(x),
                                        value: x.firmId
                                    })).concat(this.props.subscriptions
                                        .filter(x => x.subscriptionType === "Okargo" && x.agency.enabled)
                                        .map(x => ({
                                            label: getFirmName(x.agency.company),
                                            value: x.agency.companyId
                                        })))
                                    .concat(this.props.subscriptions
                                        .filter(x => x.subscriptionType === "Okargo"
                                            && x.agency.company.companyGroup  && x.agency.enabled)
                                        .map(x => ({
                                            label: getFirmName(x.agency.company.companyGroup),
                                            value: x.agency.company.companyGroupId
                                        }))), x => x.label))} />
                        </div>
                    </div>}
                <div style={{ ...fieldStyle }}>
                    <label style={{ ...labelStyle }}>Carrier: </label>
                    <div style={{ flex: 1 }}>
                        <ReduxForm.Field className="form-control"
                            name="carrierRatesPlatformId"
                            validate={[FormField.required]}
                            style={{ minWidth: 200 }}
                            component={FormField.getSelectField(_.sortBy(_.values(this.props.carrierRatesPlatforms)
                                .filter(y => y.status && y.status !== "BetaTest" && y.enabled && !platformBlacklist.some(z => z === y.code))
                                   
                                .map(x => ({ label: x.name , value: x.carrierRatesPlatformId })), x => x.label))} />
                    </div>
                </div>
                <div style={{ ...fieldStyle }} id="login">
                    <label style={{ ...labelStyle }}>Login: </label>
                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="login"
                                type={"text"}
                                autoComplete="off"
                                validate={[FormField.required, uniqLogin]}
                                component={FormField.renderField}
                            />
                        </div>
                    </form>

                </div>
                <div style={{ ...fieldStyle }} id="password">
                    <label style={{ ...labelStyle }}>Password: </label>

                    <form autoComplete="off">
                        <div style={{ flex: 1 }}>
                            <ReduxForm.Field className="form-control"
                                name="password"
                                type={this.state.isPasswordVisible ? "text" : "password"}
                                autoComplete="off"
                                validate={this.props.mode === "Create" ? [FormField.required] : []}
                                component={FormField.renderField} />
                        </div>
                        {this.props.mode === "Update" &&
                            <div>
                                Leave empty to not update
                            </div>}
                    </form>

                    <span onClick={(e) => { this.setState({ isPasswordVisible: !this.state.isPasswordVisible }) }}>
                        <PasswordToggle IsVisible={this.state.isPasswordVisible} style={{ top: "-3px" }} />
                    </span>

                </div>
                {this.authenticationType === "Token"
                    && <div style={{ ...fieldStyle }}>
                        <label style={{ ...labelStyle }}>Token: </label>
                        <div style={{ flex: 1 }}>
                            <div>
                                <ReduxForm.Field className="form-control"
                                    name="token"
                                    type={"text"}
                                    autoComplete="off"
                                    validate={this.props.mode === "Create" ? [FormField.required] : []}
                                    component={FormField.renderField} />
                            </div>
                            {this.props.mode === "Update"
                                && <div>
                                    Leave empty to not update
                                </div>}
                        </div>
                    </div>}
                <div style={{ ...fieldStyle }}>
                    <div style={{ ...labelStyle }}></div>
                    <div style={{ flex: 1 }}>
                        <button onClick={(e) => { this.setState({ isPasswordVisible: false }) }}
                            type="submit"
                            style={{ ...Colors.revertColors.main3 }}
                            disabled={this.props.submitting || this.props.pristine}
                            className="btn">
                            {this.props.mode === "Create" && getText("GenCreate")}
                            {this.props.mode === "Update" && getText("GenSaveChanges")}
                        </button>
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = ReduxForm.reduxForm({
    form: 'createCarrierRatesSettings',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(CarrierRatesSettingsForm) as any;
const createSelector = ReduxForm.formValueSelector('createCarrierRatesSettings')

let updateForm = ReduxForm.reduxForm({
    form: 'updateCarrierRatesSettings',
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true
})(CarrierRatesSettingsForm) as any;
const updateSelector = ReduxForm.formValueSelector('updateCarrierRatesSettings')

export const CreateCarrierRatesSettingsForm = connect((state: ApplicationState) => {
    return {
        initialValues: {},
        carriers: state.seed.carriers,
        carrierRatesPlatforms: state.seed.carrierRatesPlatforms,
        carrierId: createSelector(state, "carrierId"),
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        subscription: getSubscription(state.account.currentUser.clientModel),
        agencies: state.account.currentUser.clientModel.agencies,
        firmId: createSelector(state, "firmId"),
        carrierRatesSettings: state.carrierRatesSettings.entities
    } as CarrierRatesSettingsFormOwnProps;
})(createForm) as any as React.ComponentClass<CarrierRatesSettingsFormExternalProps>

export const UpdateCarrierRatesSettingsForm = connect((state: ApplicationState) => {
    let carrierRatesSettings = state.carrierRatesSettings.entities
        .find(x => x.carrierRatesSettingsId === state.carrierRatesSettings.selectedId);
    return {
        initialValues: {
            ...carrierRatesSettings,
            carrierId: state.seed.carrierRatesPlatforms[carrierRatesSettings.carrierRatesPlatformId].carrierId
        } || {},
        carriers: state.seed.carriers,
        carrierRatesPlatforms: state.seed.carrierRatesPlatforms,
        carrierId: updateSelector(state, "carrierId"),
        subscriptions: state.account.currentUser.clientModel.subscriptions
            .filter(x => x.subscriptionType === "Okargo"),
        subscription: getSubscription(state.account.currentUser.clientModel),
        agencies: state.account.currentUser.clientModel.agencies,
        firmId: updateSelector(state, "firmId"),
        carrierRatesSettings: state.carrierRatesSettings.entities
    } as CarrierRatesSettingsFormOwnProps;
})(updateForm) as any as React.ComponentClass<CarrierRatesSettingsFormExternalProps>